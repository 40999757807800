import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { Spacer } from 'components/ui-component/Spacer'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import {
  AI_MODEL_OPTIONS,
  ArticleFormValues,
  GENERATE_FOR_OPTIONS,
  NUMBER_OF_PARAGRAPHS_OPTIONS,
} from 'views/TextToText/useTextGenerator'
import { GenerateArticleButton } from './GenerateArticleButton'
import { FormattedMessage } from 'components/FormattedMessage'
import { useTranslation } from 'react-i18next'

type Props = {
  register: UseFormRegister<ArticleFormValues>
  onSubmit: any
  isLoading: boolean
  control: Control<ArticleFormValues>
  errors: FieldErrors<ArticleFormValues>
  originalTextLength: number
}

export const URL_REGEX = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
)

export const ArticleGeneratorForm = ({ register, onSubmit, isLoading, control, errors, originalTextLength }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const validateNumberOfCharacters = (value: string, formData: ArticleFormValues) => {
    const { originalText, sourceUrl } = formData
    if (sourceUrl && !originalText) {
      return true
    }

    if (Number.isNaN(Number(value))) {
      return t('articleGenerator.lengthЕrror3')
    }

    if (Number(value) > originalText.length) {
      return t('articleGenerator.lengthЕrror4', { value: originalText.length })
    }

    return true
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid container rowSpacing="24px" columnSpacing="24px">
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="generate-for-select-label" sx={{ color: theme.palette.grey[700] }}>
              <FormattedMessage id="articleGenerator.generateArticleFor" />
            </InputLabel>
            <Controller
              name="publication"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="generate-for-select-label"
                  value={value || GENERATE_FOR_OPTIONS[0].value}
                  label={<FormattedMessage id="articleGenerator.generateArticleFor" />}
                  onChange={onChange}
                >
                  {GENERATE_FOR_OPTIONS.map(({ label, value, id }) => (
                    <MenuItem key={`generate_for_item_${id}`} value={value} sx={{ color: theme.palette.grey[700] }}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={8} sx={{ height: '0px', padding: '0 !important' }} />
        <Grid item md={4} xs={12}>
          <StyledTextField
            fullWidth
            label={<FormattedMessage id="articleGenerator.sourceLabel" />}
            helperText={
              errors.originalText ? (
                <FormattedMessage id="articleGenerator.sourceError" />
              ) : (
                <>
                  <FormattedMessage id="articleGenerator.sourceDescription1" />
                  <br />
                  <FormattedMessage id="articleGenerator.sourceDescription2" />
                </>
              )
            }
            {...register('source', { required: true })}
            error={!!errors.source}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <StyledTextField
            fullWidth
            label={
              <>
                <FormattedMessage id="articleGenerator.sourceUrlLabel" /> (<FormattedMessage id="common.optional" />)
              </>
            }
            {...register('sourceUrl', { required: false, pattern: URL_REGEX })}
            error={!!errors.sourceUrl}
            helperText={
              errors.sourceUrl ? (
                <FormattedMessage id="articleGenerator.sourceUrlError" />
              ) : (
                <>
                  <FormattedMessage id="articleGenerator.sourceUrlDescription1" />
                  <br />
                  <FormattedMessage id="articleGenerator.sourceUrlDescription2" />
                </>
              )
            }
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model-select-label">
              <FormattedMessage id="common.chooseAIModel" />
            </InputLabel>
            <Controller
              name="version"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="model-select-label"
                  value={value}
                  label={<FormattedMessage id="common.chooseAIModel" />}
                  onChange={onChange}
                >
                  {AI_MODEL_OPTIONS.map(({ label, value, id }) => (
                    <MenuItem key={`model_item_${id}`} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextAreaWrapper>
            <Chip
              sx={{
                fontSize: '10px',
                lineHeight: '16px',
                textTransform: 'none',
                fontWeight: 700,
                color: theme.palette.grey[500],
                backgroundColor: theme.palette.grey[200],
                padding: '4px 0px',
                height: 'unset',
                border: `1px solid ${theme.palette.grey[500]}`,
                marginBottom: '-13px',
                marginRight: '8px',
                zIndex: '2',
              }}
              label={
                <>
                  <FormattedMessage id="common.characters" />: {originalTextLength}
                </>
              }
            />
            <StyledTextField
              fullWidth
              multiline
              rows={12}
              label={
                <>
                  <FormattedMessage id="common.pasteText" /> (<FormattedMessage id="common.optional" />)
                </>
              }
              placeholder={t('common.pleaseProvideMeTextSoICcanDoMyMagic')}
              {...register('originalText', { required: false })}
              helperText={!!errors.originalText && <FormattedMessage id="articleGenerator.textAreaError" />}
              error={!!errors.originalText}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                sx: {
                  '&::placeholder': {
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: theme.palette.grey[500],
                    paddingTop: '3px',
                    opacity: 1, // otherwise firefox shows a lighter color
                  },
                  '&:focus::placeholder': {
                    color: 'transparent',
                  },
                },
              }}
            />
          </TextAreaWrapper>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h3">
            <FormattedMessage id="articleGenerator.lengthHeadline" />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <StyledTextField
            fullWidth
            label={<FormattedMessage id="articleGenerator.lengthLabel" />}
            {...register('characters', { required: true, validate: validateNumberOfCharacters })}
            helperText={
              errors.characters ? (
                errors.characters.message
              ) : (
                <>
                  <span>
                    <FormattedMessage id="articleGenerator.lengthЕrror1" />
                  </span>
                  <br />
                  <span>
                    <FormattedMessage id="articleGenerator.lengthЕrror2" values={{ value: originalTextLength }} />
                  </span>
                </>
              )
            }
            error={!!errors.characters}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="paragraphs-select-label">
              <FormattedMessage id="articleGenerator.paragraphLabel" />
            </InputLabel>
            <Controller
              name="paragraphs"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="paragraphs-select-label"
                  value={value || NUMBER_OF_PARAGRAPHS_OPTIONS[0].value}
                  label={<FormattedMessage id="articleGenerator.paragraphLabel" />}
                  onChange={onChange}
                >
                  {NUMBER_OF_PARAGRAPHS_OPTIONS.map(({ label, value, id }) => (
                    <MenuItem key={`paragraphs_item_${id}`} value={value}>
                      <FormattedMessage id={label} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Spacer v size={40} />
        <Grid item md={12} xs={12}>
          <Typography variant="h3">
            <FormattedMessage id="articleGenerator.focusHeadline" />{' '}
            <Typography sx={{ fontWeight: 400, display: 'inline' }}>
              (<FormattedMessage id="common.optional" />)
            </Typography>
          </Typography>
          <Spacer v size={8} />
          <Typography variant="bodybg">
            <FormattedMessage id="articleGenerator.focusSubHeadline" />
          </Typography>
          <Spacer size={24} v />
          <TextField
            fullWidth
            multiline
            rows={4}
            label={
              <>
                <FormattedMessage id="articleGenerator.focusSubHeadline" /> (<FormattedMessage id="common.optional" />)
              </>
            }
            placeholder={t('articleGenerator.focusPlaceHolder')}
            {...register('focus')}
          />
        </Grid>
      </Grid>
      <Spacer v size={40} />
      <GenerateArticleButton
        isLoading={isLoading}
        disabled={isLoading}
        buttonText={<FormattedMessage id="articleGenerator.generateArticle" />}
      />
    </form>
  )
}

const TextAreaWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const StyledTextField = styled(TextField)({
  '& label': {
    color: '#364152',
    lineHeight: '20px',
  },
})
